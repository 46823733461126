require("jquery")
require("turbolinks")
require("bootstrap")
//require("packs/creative/cbpAnimatedHeader")
//require("packs/creative/classie")
require("packs/creative/jquery.easing.min")
require("packs/creative/jquery.fittext")
require("packs/creative/wow.min")
require("packs/creative/creative")

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';




